<!-- 云文档 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="cloud-container">
    <el-tabs class="filetab" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="所有文档" name="first">
        <breadcrumb
          :BreadcrumbData="BreadcrumbData"
          @return="returnUrl"
        ></breadcrumb>
        <!-- 筛选 -->
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>文档名称/关键词:</span>
            <el-input
              v-model="params.fileName"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
            <span>所属公司:</span>
            <el-select
              class="common-screen-input"
              @change="commonFun"
              v-model="params.companyId"
              placeholder="请选择公司"
              filterable
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="common-input-container">
            <span>创建人:</span>
            <el-input
              v-model="params.createName"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <br />
          <div class="common-input-container">
            <common-date
              @commonGetDate="commonGetDate"
              ref="commonReset"
            ></common-date>
          </div>
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                getCloudList();
              }
            "
            >查 询</el-button
          >
          <el-button class="common-screen-btn" plain @click="() => resetBtn()"
            >重 置</el-button
          >
        </div>
        <el-button
          v-show="!this.$route.query.id"
          class="common-screen-btn btn-width common-noMarginLeft"
          type="primary"
          @click="addDrawer('add', '', '')"
          >新增类目</el-button
        >
        <!-- 树形表格 -->
        <el-table class="common-table" :data="filelist">
          <!-- <el-table-column type="expand">
            <template slot-scope="scope">
              {{ scope.row }}
            </template>
          </el-table-column> -->
          <el-table-column label="文档名称" :formatter="tableColumn">
            <template slot-scope="scope">
              <img
                v-if="scope.row.type == 1"
                class="file-icons"
                src="~@/assets/images/folder.svg"
              />
              <el-button
                v-if="scope.row.type == 1"
                type="text"
                size="small"
                @click="() => seeMore(scope.row)"
                >{{ scope.row.fileName }}</el-button
              >
              <common-picture
                v-else
                :fileUrl="scope.row.path"
                :fileType="
                  Config.fileType.imgType.includes(
                    scope.row.path.split('.')[
                      scope.row.path.split('.').length - 1
                    ]
                  )
                    ? 'imgText'
                    : 'file'
                "
                :fileName="scope.row.fileName"
              ></common-picture>
              <!-- <el-button v-else type="text" size="small" disabled>{{
                scope.row.fileName
              }}</el-button> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="time"
            label="上传文档时间"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="nickName"
            label="创建人"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column prop="platformName" label="操作">
            <template slot-scope="scope">
              <!-- <el-button
                v-if="scope.row.type == 1 && scope.row.canEdit == 1"
                type="text"
                size="mini"
                @click="addDrawer('edit', scope.row.fileId, scope.row.fileName)"
                >编辑
              </el-button>
              <el-button
                v-if="scope.row.type == 1 && scope.row.canEdit == 0"
                type="text"
                size="mini"
                disabled
                >编辑
              </el-button> -->

              <el-button
                v-if="scope.row.type == 0"
                type="text"
                size="mini"
                @click="down(scope.row)"
                >下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="我的文档" name="second">
        <breadcrumb
          :BreadcrumbData="BreadcrumbData"
          @return="returnUrl"
        ></breadcrumb>
        <!-- 筛选 -->
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>文档名称/关键词:</span>
            <el-input
              v-model="params.fileName"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
          </div>
          <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
            <span>所属公司:</span>
            <el-select
              class="common-screen-input"
              @change="commonFun"
              v-model="params.companyId"
              placeholder="请选择公司"
              filterable
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <br />
          <div class="common-input-container">
            <common-date
              @commonGetDate="commonGetDate"
              ref="commonReset"
            ></common-date>
          </div>
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                getCloudList();
              }
            "
            >查 询</el-button
          >
          <el-button class="common-screen-btn" plain @click="() => resetBtn()"
            >重 置</el-button
          >
        </div>
        <div v-show="this.$route.query.id" class="uploadBtn-container common-noMarginLeft">
          <img class="upload-icon" src="~@/assets/images/upload.svg" />
          上传
          <input
            @input="
              (e) => {
                uploadFiles(e, params.parentId);
              }
            "
            class="uploadBtn-input"
            type="file"
            multiple
          />
        </div>
        <!-- <el-button
          v-show="this.$route.query.id"
          class="common-screen-btn up"
          type="primary"
          @click="addDrawer('add', '', '')"
        >
          <img src="~@/assets/images/upload.svg" class="upload-icon" />
          上传</el-button
        > -->
        <el-button
          v-show="!this.$route.query.id"
          class="common-screen-btn btn-width common-noMarginLeft"
          type="primary"
          @click="addDrawer('add', '', '')"
          >新增类目</el-button
        >
        <el-button
          v-show="this.$route.query.id"
          class="common-screen-btn new-add btn-width"
          plain
          @click="addDrawer('add', params.parentId, '')"
          >新增子类目</el-button
        >
        <!-- 树形表格 -->
        <el-table class="common-table" :data="myfilelist">
          <!-- <el-table-column type="expand">
            <template slot-scope="scope">
              {{ scope.row }}
            </template>
          </el-table-column> -->
          <el-table-column label="文档名称" :formatter="tableColumn">
            <template slot-scope="scope">
              <img
                v-if="scope.row.type == 1"
                class="file-icons"
                src="~@/assets/images/folder.svg"
              />
              <el-button
                v-if="scope.row.type == 1"
                type="text"
                size="small"
                @click="() => seeMore(scope.row)"
                >{{ scope.row.fileName }}</el-button
              >
              <common-picture
                v-else
                :fileUrl="scope.row.path"
                :fileType="
                  Config.fileType.imgType.includes(
                    scope.row.path.split('.')[
                      scope.row.path.split('.').length - 1
                    ]
                  )
                    ? 'imgText'
                    : 'file'
                "
                :fileName="scope.row.fileName"
              ></common-picture>
              <!-- <el-button v-else type="text" size="small" disabled>{{
                scope.row.fileName
              }}</el-button> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="time"
            label="上传文档时间"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="nickName"
            label="创建人"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.type == 1 && scope.row.canEdit == 1"
                type="text"
                size="mini"
                @click="addDrawer('edit', scope.row.fileId, scope.row.fileName)"
                >编辑
              </el-button>
              <el-button
                v-if="scope.row.type == 1 && scope.row.canEdit == 0"
                type="text"
                size="mini"
                disabled
                >编辑
              </el-button>
              <!-- <div class="uploadBtn-container" v-if="scope.row.type == 1">
                上传文档
                <input
                  @input="
                    (e) => {
                      uploadFiles(e, scope.row.fileId);
                    }
                  "
                  class="uploadBtn-input"
                  type="file"
                  multiple
                />
              </div> -->
              <el-button
                v-if="scope.row.type == 0"
                type="text"
                size="mini"
                @click="down(scope.row)"
                >下载
              </el-button>
              <el-button
                v-if="scope.row.canEdit == 0"
                type="text"
                size="mini"
                disabled
                >移动
              </el-button>
              <el-button
                v-else
                type="text"
                size="mini"
                @click="
                  () => {
                    getFolderList(scope.row);
                  }
                "
                >移动
              </el-button>
              <el-popconfirm
                v-if="scope.row.type == 0"
                class="popcon"
                title="确定删除吗？"
                @confirm="del(scope.row)"
              >
                <el-button type="text" size="mini" slot="reference">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="是否公开">
            <template slot-scope="scope" v-if="scope.row.type == 0">
              <el-switch
                v-model="scope.row.showFlag"
                :active-value="1"
                :inactive-value="0"
                active-color="#1890FF"
                inactive-color="#DBDBDB"
                @change="changeShow(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      v-dialogDrag
      :title="dialogTit"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :before-close="
        () => {
          canleBtn();
        }
      "
    >
      <el-form ref="form" :model="form" :rules="formRules" label-width="110px">
        <el-form-item label="类目名称：" prop="name">
          <el-input v-model.trim="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="canleBtn">取 消</el-button>
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="submitBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="移动到"
      :visible.sync="moveDialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <el-tree
        :data="data"
        :props="defaultProps"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="moveDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="moveSubmitBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "./children/breadCrumb";
import { Config, tableColumn } from "../../utils";
import CommonDate from "../../components/common/commonDate";
import CommonPicture from "../../components/common/commonPicture.vue";
import {
  getList,
  createFileCategory,
  uploadFile,
  delfile,
  showflag,
  folderList,
  moveFile
} from "../../service/header.js";
import { mapState } from "vuex";

export default {
  name: "Cloud",
  components: {
    Breadcrumb,
    CommonDate,
    CommonPicture
  },
  props: {},
  data() {
    return {
      Config,
      tableColumn,
      activeName: "first",
      BreadcrumbData: [
        {
          title: "云文档",
          isLink: true,
          url: "/header/cloud?parentId=" + 0,
          goback: -1
        }
      ],
      params: {
        type: 1,
        parentId: 0
      }, //获取文件列表参数
      loading: false,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      // 树形数据列表，默认为空
      filelist: [],
      myfilelist: [],
      // 树形数据子项
      list: [],
      dialogTit: "",
      dialogVisible: false,
      form: {
        name: ""
      }, //新增、修改参数
      formRules: {
        name: [{ required: true, message: "请输入文件夹名称", trigger: "blur" }]
      },
      tabName: "first",
      imgDialog: false,
      url: "",
      imgurl: [],
      idStr: [],
      moveDialogVisible: false,
      data: [],
      defaultProps: {
        children: "child",
        label: "name"
      },
      move: {}
      /////////////////////////////
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["btnLoading"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    commonGetDate(startTime, endTime) {
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getCloudList();
    },
    handleClick(tab) {
      this.params = {};
      this.params.companyId = this.rolesCompany[0].id;
      this.BreadcrumbData = [
        {
          title: "云文档",
          isLink: true,
          url: "/header/cloud"
          // url: "/header/cloud?id=" + this.idStr[0]
        }
      ];
      this.$router.push({ path: "/header/cloud" });
      this.idStr = [];
      console.log(tab);
      if (tab.name == "first") {
        this.tabName = "first";
        this.params.type = 1;
      } else {
        this.tabName = "second";
        this.params.type = 2;
      }
      this.getCloudList();
    },
    commonFun() {
      this.getCloudList();
    },
    resetBtn() {
      if (this.tabName == "first") {
        this.params = {
          companyId: this.rolesCompany[0].id,
          type: 1
        };
      } else {
        this.params = {
          companyId: this.rolesCompany[0].id,
          type: 2
        };
      }

      this.$refs.commonReset.resetFun();
      // 获取数据
      this.getCloudList();
    },
    async getCloudList() {
      this.loading = true;
      let params = { ...this.params };
      console.log(params, "******");
      const { data } = await getList(params);
      if (params.type == 1) {
        this.filelist = data;
      } else {
        this.myfilelist = data;
      }
      this.loading = false;
    },
    addDrawer(type, id, name) {
      if (type == "add") {
        delete this.form.name;
        delete this.form.id;
        this.form.parentId = id;
        this.form.companyId = this.params.companyId;
        this.dialogTit = "新增类目";
      } else {
        this.form.parentId = this.idStr[this.idStr.length - 1];
        this.form.companyId = this.params.companyId;
        this.dialogTit = "编辑类目";
        this.form.id = id;
        this.form.name = name;
      }
      this.dialogVisible = true;
      // 刷新列表
    },
    canleBtn() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    async submitBtn() {
      this.$vuex.commit("btnLoading", true);
      let params = { ...this.form };
      console.log(params, "操作params");
      const res = await createFileCategory(params);
      if (res.code != 200) {
        return this.$message.error("操作失败");
      }
      this.getCloudList();
      this.$message.success("操作成功");
      this.$refs.form.resetFields();
      this.dialogVisible = false;
      this.$vuex.commit("btnLoading", false);
    },
    down(row) {
      window.location.href = row.path;
    },
    async uploadFiles(e, id) {
      let fileList = [...e.target.files];
      for (let i = 0; i < fileList.length; i++) {
        var file = fileList[i];
        var fileName = file.name;
        if (file.size / 1024 > 1024 * 50) {
          // this.loading = false;
          e.target.value = "";
          return this.$message.error("文件大小不能超过50M");
        }
        var format = fileName.split(".")[fileName.split(".").length - 1];
        if (!Config.fileType.PWEIType.includes(format)) {
          e.target.value = "";
          // this.loading = false;
          return this.$message.error(
            `只能上传 ${Config.fileMessage.PWEIType} 等格式的文件`
          );
        }
        // this.loading = false;
      }
      this.loading = true;
      const res = await uploadFile({ id: id, file: fileList });
      this.loading = false;
      if (res.code != 200) {
        return this.$message.error("上传失败");
      }
      this.getCloudList();
      this.$message.success("上传成功");
      e.target.value = "";
    },
    async del(row) {
      console.log(row);
      const res = await delfile({ id: row.fileId });
      if (res.code != 200) {
        return this.$message.error("删除失败");
      }
      this.getCloudList();
      this.$message.success("删除成功");
    },
    async changeShow(row) {
      let params = {
        fileId: row.fileId,
        state: row.showFlag
      };
      const res = await showflag(params);
      if (res.code != 200) {
        return this.$message.error("操作失败");
      }
      this.getCloudList();
      this.$message.success("操作成功");
    },
    seeMore(row) {
      const set = new Set(this.BreadcrumbData);
      set.add({
        title: `${row.fileName}`,
        isLink: true
      });
      // this.idStr.push(row.fileId)
      const setId = new Set(this.idStr);
      setId.delete(row.fileId);
      setId.add(row.fileId);
      this.idStr = Array.from(setId);
      this.$router.push({ path: "/header/cloud?id=" + this.idStr });

      this.BreadcrumbData = Array.from(set);
      this.params.parentId = row.fileId;
      // 点击拿到 parentId 再去请求接口
      this.getCloudList();
    },
    returnUrl(index) {
      console.log(index);
      console.log(this.idStr, "最新的IDStr");
      // let idArr = this.$route.query.id.split(",");
      // console.log(idArr,'2222222222222222');
      if (index - 1 == 0) {
        this.params.parentId = this.idStr[0]; //this.idStr[0] = 1

        this.idStr = this.idStr.filter((item, i) => {
          return index - 1 >= i;
        });
        this.$router.push("/header/cloud?id=" + this.idStr[0]); //this.idStr[0] = 1
      } else if (index - 1 < 0) {
        this.params.parentId = 0;
        this.idStr = [];
        this.$router.push("/header/cloud");
      } else {
        this.params.parentId = this.idStr[index - 1]; //5
        this.idStr = this.idStr.filter((item, i) => {
          return index - 1 >= i;
        });
        console.log(this.idStr, "过滤后的"); //[1,5]
        this.$router.push({
          path: "/header/cloud?id=" + this.idStr[index - 1]
        });
      }
      this.getCloudList();
      this.BreadcrumbData = this.BreadcrumbData.filter((item, i) => {
        return index >= i;
      });
    },
    cancel() {
      let num = this.idStr.length;
      if (num && num != 1) {
        this.params.parentId = this.idStr[num - 2];
        this.idStr.pop(this.idStr[num - 2]);
        this.$router.push({
          path: "/header/cloud?id=" + this.idStr[num - 2]
        });
        this.BreadcrumbData.pop(
          this.BreadcrumbData[this.BreadcrumbData.length - 2]
        );
        this.getCloudList();
      } else if (num == 1) { //idstr = [1]
        this.params.parentId = 0;
        this.$router.push("/header/cloud");
        this.idStr = [];
        this.BreadcrumbData.pop(
          this.BreadcrumbData[this.BreadcrumbData.length - 2]
        );
        this.getCloudList();
      } 
      else if(num == 0){
        // this.params.parentId = 0;
        // this.$router.push("/header/cloud");
        console.log('走2遍？？？？？');
        num = -1
        // this.$router.go(-1)
        // this.BreadcrumbData = [
        //   {
        //     title: "云文档",
        //     isLink: true,
        //     url: "/header/cloud"
        //   }
        // ];
      }else{
        console.log('-1//////////////////');
      }

      
    },
    async getFolderList(row) {
      this.loading = true;
      const { data } = await folderList({ companyId: this.params.companyId });
      this.data = data;
      if (row.type == 0) {
        this.move.fileIds = row.fileId + "";
      } else {
        this.move.folderIds = row.fileId + "";
      }
      this.moveDialogVisible = true;
      this.loading = false;
    },
    handleNodeClick(a) {
      this.move.toId = a.id;
    },
    async moveSubmitBtn() {
      this.$vuex.commit("btnLoading", true);
      let params = { ...this.move };
      if (!params.toId) {
        this.$vuex.commit("btnLoading", false);
        return this.$message.error("请先选择文件夹！");
      }
      const res = await moveFile(params);
      // if (res.code != 200) {
      //  return this.$message.error({message: res.message});
      // }
      if (res.code == 200) {
        this.getCloudList();
        this.moveDialogVisible = false;
        this.$vuex.commit("btnLoading", false);
        return this.$message.success("移动成功");
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    this.$router.push({ path: "/header/cloud" });
    this.getCloudList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("popstate", this.cancel, false);
  }
};
</script>

<style lang="less" scoped>
.cloud-container {
  text-align: left;
  height: 100%;

  .up {
    width: 100px;
    margin-left: 16px !important;
  }
}
.filetab {
  height: 100%;
  overflow-y: scroll;
}
.zk-table {
  // flex: 1;
  margin: 16px 0;
  // height: 500px;
  overflow: auto;
}
/deep/ .zk-table__header-cell {
  font-weight: 400 !important;
}
/deep/ .zk-table__cell-inner {
  box-sizing: border-box;
}
/deep/ .zk-table__header-row {
  background-color: transparent;
}
.uploadBtn-container {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  background: #1890ff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
  margin-left: 16px;
  position: relative;
  .uploadBtn-input {
    width: 100px;
    height: 32px;

    cursor: pointer;
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
/deep/ .el-popover__reference-wrapper {
  margin-left: 8px;
}
.cansee {
  overflow: auto;
  height: 750px;
}
/deep/.el-tree-node__content:hover {
  color: #1890ff;
}
/deep/ .is-current {
  color: #1890ff;
}
.file-icons {
  width: 22px;
  vertical-align: middle;
  margin-right: 8px;
}
.btn-width {
  width: 100px;
  font-size: 14px;
}

.upload-icon {
  vertical-align: middle;
}
.new-add {
  // width: 100px;
  border: 1px solid #1890ff;
  color: #1890ff;
  font-size: 14px;
}
/deep/ .el-tabs__content{
  padding: 16px 32px;
}
</style>
<style lang="less">
.el-popover {
  .el-popconfirm__action {
    margin-top: 12px;
    .el-button--mini {
      height: 26px !important;
      line-height: 12px;
      margin-left: 16px;
    }
  }
}
</style>
